import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <p>{`Add Font Awesome Icons to builds by default`}</p>
    <p><a parentName="p" {...{
        "href": "https://fontawesome.com/icons?d=gallery"
      }}>{`https://fontawesome.com/icons?d=gallery`}</a></p>
    <p>{`No icons in topbar, utility links`}</p>
    <p>{`Use of Icons`}</p>
    <p>{`In-line Images`}</p>
    <p>{`Cropping`}</p>
    <p>{`Mobile Views`}</p>
    <p>{`Distortion`}</p>
    <p>{`Scroll bars`}</p>
    <p>{`In-line Images`}</p>
    <p>{`Cropping`}</p>
    <p>{`Distortion`}</p>
    <p>{`Padding for text wrap/mobile views`}</p>
    <p>{`In-line vs. block layout`}</p>
    <p>{`Banner Images`}</p>
    <p>{`Centering vs. Left-aligned`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p>{`external links`}</p>
    <p><inlineCode parentName="p">{`<p>this is text and here is an <a href="#">external link <i class="icon-external-link"></i></a></p>`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      